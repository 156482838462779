'use client'

import { FunctionComponent, ReactNode, useContext, useEffect, useState } from 'react'
import { ModalContext, ModalContextType } from '../../context/ModalContext'
import Icon from '../Icon/Icon'
import { Icons } from '../Icon/constants'
import { useTranslation } from '../../context/ConfigContext'
import clsx from 'clsx'

interface ModalProps extends ModalInterface {
  dark?: boolean
  showLogo?: boolean
  children: ReactNode
  center?: boolean
  anchor?: 'left' | 'right' | 'center'
  title?: string
  className?: string
  allowScrolling?: boolean
}

export const Modal: FunctionComponent<ModalProps> = ({
  className,
  uuid,
  dark = true,
  showLogo = true,
  onClose,
  children,
  center = false,
  anchor = 'left',
  allowScrolling = false,
  title,
}) => {
  const { modals, removeModal } = useContext(ModalContext) as ModalContextType
  const [visible, setVisible] = useState(false)

  const handleClose = () => {
    onClose && onClose()
    removeModal(uuid)
    document.body.classList.remove('contrast-modal-open')
  }

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        onClose && onClose()
      }
    }
    window.addEventListener('keydown', close)
    return () => window.removeEventListener('keydown', close)
  }, [])

  useEffect(() => {
    if (!allowScrolling) {
      document.body.classList.add('no-overflow')
    }
    return () => {
      if (modals.length === 1) {
        if (!allowScrolling) {
          document.body.classList.remove('no-overflow')
        }
      }
    }
  }, [allowScrolling])

  useEffect(() => {
    setVisible(true)
  }, [])

  const t = useTranslation()

  return (
    <div className={`modal-overlay modal-${anchor} ${className ?? ''}`} key={uuid}>
      <section
        className={clsx('modal', {
          dark,
          light: !dark,
          visible,
        })}
        role='dialog'
        aria-modal='true'>
        <div className='header-modal'>
          <button
            type='button'
            className={`close ${dark && 'contrast'}`}
            onClick={handleClose}
            autoFocus
            aria-label={t('Close', {}, false)}>
            <Icon iconName={Icons.CLOSE} />
          </button>
          {showLogo && (
            <picture className='messika-logo'>
              <Icon iconName={Icons.MESSIKA_LOGO_WHITE} width={114} height={32} />
            </picture>
          )}
          {title && <h4 className='title-h4'>{title}</h4>}
        </div>
        <div className={center ? 'center modal-content' : 'modal-content'}>{children}</div>
      </section>
      <div className='overlay' onClick={handleClose} tabIndex={-1}></div>
    </div>
  )
}
