import ProductConfigurationBraceletMyMove from '../Product/ProductConfigurationBraceletMyMove'
import { CartItem as CartItemType } from '../../../domain/cart'
import { useForm } from 'react-hook-form'
import { useContext } from 'react'
import { findVariant } from '../../utils/Product.utils'
import {
  BRACELET_MY_MOVE_COLOR_ATTRIBUTE_NAME,
  BRACELET_MY_MOVE_SIZE_ATTRIBUTE_NAME,
} from '../Product/ProductConfiguration.utils'
import { CartListContext } from '../CartList/CartListContext'
import CustomSelect from '../Form/InputWrapper/CustomSelect'
import useUpdateProductFromCart from '../../../core/hook/cartHooks/useUpdateProductFromCart'

type CartItemBraceletMyMoveFormData = {
  size: number
  color?: number
  productOption: string
}

const CartItemBraceletMyMoveContainer = ({
  item,
  onUpdate,
}: {
  item: CartItemType
  onUpdate: (updating: boolean) => void
}) => {
  const updateProductFromCart = useUpdateProductFromCart()
  const { addProductOnUpdatingList, removeProductFromUpdatingList } = useContext(CartListContext)

  const { setValue, watch } = useForm<CartItemBraceletMyMoveFormData>({
    defaultValues: {
      size: item.configurable_options[0].value_id,
      color: item.configurable_options[1].value_id,
      productOption: item.customizable_options?.[0]?.values?.[0]?.value,
    },
  })

  const onChangeBraceletMyMoveProduct = (size: number, color: number, productOption: string) => {
    const variant = findVariant(item.product, [
      { value: size, code: BRACELET_MY_MOVE_SIZE_ATTRIBUTE_NAME },
      { value: color, code: BRACELET_MY_MOVE_COLOR_ATTRIBUTE_NAME },
    ])

    if (!variant?.product) {
      return
    }

    onUpdate(true)
    updateProductFromCart(
      item,
      undefined,
      [
        { value_index: size, attribute_id: item.configurable_options[0].id },
        { value_index: color, attribute_id: item.configurable_options[1].id },
      ],
      [
        {
          value_string: productOption,
          id: item.product.options?.[0].option_id ?? 0,
        },
      ],
    ).then(() => onUpdate(false))
  }

  const selectedValues = watch()

  const onChangeSize = (size: number) => {
    setValue('size', size)
    setValue('color', undefined)
    addProductOnUpdatingList(item.id)
  }
  const onChangeColor = (color: number) => {
    setValue('color', color)

    onChangeBraceletMyMoveProduct(selectedValues.size, color, selectedValues.productOption)
    removeProductFromUpdatingList(item.id)
  }
  const onChangeProductOption = (productOption: string) => {
    setValue('productOption', productOption)

    if (selectedValues.color) {
      onChangeBraceletMyMoveProduct(selectedValues.size, selectedValues.color, productOption)
      removeProductFromUpdatingList(item.id)
    } else {
      addProductOnUpdatingList(item.id)
    }
  }

  return (
    <div className='cart-item-form-configurable-product'>
      <ProductConfigurationBraceletMyMove
        typeExtended={item.product.type_extended?.id ?? 0}
        product={item.product}
        size={selectedValues.size}
        onChangeSize={(value) => onChangeSize(value)}
        color={selectedValues.color}
        onChangeColor={(value) => onChangeColor(value)}
        disabledOptionsWhenNotInStock
        showOnlyAvailableColorBySize
      />
      {item.product.options?.[0] && (
        <CustomSelect
          label={item.product.options[0].title ?? ''}
          onChange={(valueIndex) => {
            onChangeProductOption(String(valueIndex))
          }}
          selectedValue={selectedValues.productOption}
          list={
            item.product.options[0].value?.map((value) => ({
              title: value?.title ?? '',
              value: String(value?.option_type_id) ?? '',
              id: String(value?.option_type_id) ?? '',
            })) ?? []
          }
        />
      )}
    </div>
  )
}

export default CartItemBraceletMyMoveContainer
