import { useMemo } from 'react'
import { CartItem as CartItemType } from '../../../domain/cart'
import CartItemCarving from './CartItemCarving'
import getIsItemStillValidOnCart from '../CheckoutWorkflow/getIsItemStillValidOnCart'
import useUpdateCarvingOfCartItem from '../../../core/hook/cartHooks/useUpdateCarvingOfCartItem'

const CartItemSimpleProduct = ({
  item,
  onUpdate,
}: {
  item: CartItemType
  onUpdate: (updating: boolean) => void
}) => {
  const updateCarving = useUpdateCarvingOfCartItem()

  const isItemStillValidOnCart = useMemo(() => getIsItemStillValidOnCart(item), [item])

  // Change Carving on Simple Product
  const onChangeCarving = (carving?: Carving) => {
    onUpdate(true)
    updateCarving(item, carving).then(() => onUpdate(false))
  }

  if (!isItemStillValidOnCart) {
    return null
  }

  return (
    <div className='cart-item-form-simpleproduct'>
      <CartItemCarving item={item} onChange={onChangeCarving} />
    </div>
  )
}

export default CartItemSimpleProduct
