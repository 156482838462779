import { CartItem as CartItemType } from '../../../domain/cart'
import { ROLE_THUMBNAIL } from '../../utils/media.utils'
import { findOneProductMediaGalleryByRole, getMyMoveCustomImage } from '../../utils/Product.utils'
import { getSelectedVariantsForBundleProduct } from '../Product/ProductConfigurationBundleProduct'
import { getValuesFromBundleCartItem } from './CartItemBundleMyMoveContainer'

const getBundleCartItemImage = (item: CartItemType) => {
  const { myMoveColor, braceletMyMoveSize, braceletMyMoveColor } = getValuesFromBundleCartItem(item)

  if (!myMoveColor || !braceletMyMoveSize || !braceletMyMoveColor) {
    return null
  }

  const selectedVariantsForBundleMyMove = getSelectedVariantsForBundleProduct(
    item.product,
    myMoveColor,
    braceletMyMoveColor,
    braceletMyMoveSize,
  )

  if (!selectedVariantsForBundleMyMove) {
    return null
  }

  return getMyMoveCustomImage(item.product, selectedVariantsForBundleMyMove)
}

export const getImageToShowCartItem = (item: CartItemType) => {
  if (item.product.__typename === 'BundleProduct') {
    const bundleCartImage = getBundleCartItemImage(item)

    if (bundleCartImage) {
      return bundleCartImage
    }
  }

  return item.product.media_gallery
    ? findOneProductMediaGalleryByRole(item.product.media_gallery, ROLE_THUMBNAIL)
    : null
}

