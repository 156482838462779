import clsx from 'clsx'
import React, { InputHTMLAttributes, ReactNode } from 'react'

type InputRadioProps = InputHTMLAttributes<HTMLInputElement> & {
  label?: string | ReactNode
  error?: string
  showBorderCheckbox?: boolean
}

const InputCheckbox = React.forwardRef<HTMLInputElement, InputRadioProps>(
  ({ className, label, error, showBorderCheckbox = false, ...rest }, ref) => {
    const classes = clsx('checkbox-wrapper', !!className && className)

    const classesLabel = clsx(
      'input-checkbox',
      !!className && className,
      showBorderCheckbox && 'show-borders',
      rest.disabled && 'disabled',
    )

    return (
      <div className={classes}>
        <label htmlFor={rest.id} className={classesLabel}>
          <input type='checkbox' ref={ref} {...rest} />
          <span className='checkbox-case' />
          <span className='checkbox-text'>{label}</span>
        </label>
        {error && <span className='error'>{error}</span>}
      </div>
    )
  },
)

export default InputCheckbox
