'use client'
import { zodResolver } from '@hookform/resolvers/zod'
import { GraphQLError } from 'graphql'
import { useContext, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import * as z from 'zod'
import useProductAlertGuestNotifyInStock, {
  ProductAlertGuestNotifyInput,
  useProductAlertCustomerNotifyInStock,
} from '../../../core/hook/useProductAlertGuestNotifyInStock'
import { ModalContext, ModalContextType } from '../../context/ModalContext'
import { UserContext } from '../../context/UserContext'
import { Button } from '../Button/Button'
import InputCheckbox from '../Form/InputCheckbox'
import SelectWrapper from '../Form/InputWrapper/SelectWrapper'
import TextWrapper from '../Form/InputWrapper/TextWrapper'
import { Modal } from '../Modal/Modal'
import { gtmCategory, gtmPush } from '../../utils/gtmHelpers'
import { AlertNotificationContext } from '../../context/AlertNotificationContext'
import { useTranslation } from '../../context/ConfigContext'
import { handleError } from '../../../core/hook/utils'
import { CivilityOptions } from '../Form/Civility'

type FormData = {
  email: string
  firstname: string
  lastname: string
  prefix: string
  terms: boolean
}

const gtmEvent = (product) => {
  const storedValues = JSON.parse(window.localStorage.getItem('productValues') as any)
  const gtmCategoryObj = gtmCategory(product.gtm_categories)

  gtmPush({
    event: 'inscription_alert_stock',
    ecommerce: {
      currency: product.price_range.minimum_price.regular_price.currency,
      value: product.price_range.minimum_price.regular_price.value,
      items: [
        {
          item_name: product.gtm_name ?? product.name,
          item_id: product.sku,
          price: product.price_range.minimum_price.regular_price.value,
          item_brand: 'Messika',
          index: storedValues?.position ?? '',
          item_list_id: storedValues ? storedValues.listId : 'produit',
          item_list_name: storedValues ? storedValues.listName : 'Produit',
          item_variant: product.sku,
          ...gtmCategoryObj,
        },
      ],
    },
  })
}

const ModalNotifyInStock = ({
  onClose,
  onSuccess,
  productSku,
  product,
}: {
  onClose: () => void
  onSuccess: () => void
  productSku: string
  product: Product | VariantProduct
}) => {
  const addAlertNotificationOnProduct = useProductAlertGuestNotifyInStock()
  const [errorRequest, setErrorRequest] = useState('')
  const t = useTranslation()

  const formSchema = z.object({
    firstname: z.string().min(1, { message: t('This is a required field.', {}, false) }),
    lastname: z.string().min(1, { message: t('This is a required field.', {}, false) }),
    prefix: z.string().min(1, { message: t('This is a required field.', {}, false) }),
    email: z
      .string()
      .min(1, { message: t('This is a required field.', {}, false) })
      .email({
        message: t('Please enter a valid email address (Ex: johndoe@domain.com).', {}, false),
      }),
    terms: z.literal<boolean>(true, {
      errorMap: () => ({ message: t('This is a required field.', {}, false) }),
    }),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    resolver: zodResolver(formSchema),
  })

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    const input: ProductAlertGuestNotifyInput = {
      firstname: data.firstname,
      lastname: data.lastname,
      email: data.email,
      prefix: data.prefix,
      productSku,
    }

    try {
      await addAlertNotificationOnProduct(input)
      setErrorRequest('')
      onSuccess()
      gtmEvent(product)
      onClose()
    } catch (error) {
      const { message } = error as GraphQLError
      setErrorRequest(message)
      handleError(error, false)
    }
  }

  return (
    <div className='modal-notify-in-stock-form'>
      <h3 className='title-h5 modal-notify-in-stock-form-title'>
        {t('product_alert_popup_title', {}, true)}
      </h3>
      <div className='modal-notify-in-stock-form-description'>
        <p>{t('product_alert_popup_description', {}, true)}</p>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='modal-notify-in-stock-form-inputs'>
          <SelectWrapper {...register('prefix')} error={errors.prefix?.message}>
            <CivilityOptions />
          </SelectWrapper>
          <TextWrapper
            {...register('firstname')}
            label={`${t('Firstname', {}, false)}*`}
            error={errors.firstname?.message}
          />
          <TextWrapper
            {...register('lastname')}
            label={`${t('Lastname', {}, false)}*`}
            error={errors.lastname?.message}
          />
          <TextWrapper
            {...register('email')}
            label={`${t('Email', {}, false)}*`}
            error={errors.email?.message}
            type='email'
          />
        </div>

        <InputCheckbox
          id='terms'
          {...register('terms')}
          label={t('product_alert_popup_gdpr_text', {}, false)}
          className='modal-notify-in-stock-form-terms'
          showBorderCheckbox
          error={errors.terms?.message}
        />

        {errorRequest && <p className='modal-notify-in-stock-form-errorrequest'>{errorRequest}</p>}
        <div className='modal-notify-in-stock-form-submit'>
          <Button
            classname='button black'
            type='submit'
            label={t('product_alert_popup_submit_text', {}, false)}
            style='dark'
            size='large'
          />
        </div>

        <p className='modal-notify-in-stock-form-condition'>
          {t('product_alert_popup_footer', {}, true)}
        </p>
      </form>
    </div>
  )
}

const ProductGetNotifyInStockAsGuest = ({ product }: { product: Product | VariantProduct }) => {
  const { addNotification } = useContext(AlertNotificationContext)
  const { addModal, removeModal } = useContext(ModalContext) as ModalContextType
  const t = useTranslation()

  const onClick = () => {
    gtmEvent(product)

    if (!product.sku) {
      return
    }

    const uuid = crypto.randomUUID()

    addModal({
      uuid,
      component: (
        <Modal
          uuid={uuid}
          className='modal-notify-in-stock'
          dark={false}
          anchor='center'
          showLogo={false}>
          <ModalNotifyInStock
            onClose={() => removeModal(uuid)}
            onSuccess={() =>
              addNotification(t('catalog-product-alert-stock-confirmation-text', {}, false))
            }
            productSku={product.sku}
            product={product}
          />
        </Modal>
      ),
    })
  }

  return (
    <Button
      classname='black small'
      label={t('product_alert_popup_title', {}, false)}
      type='button'
      style='dark'
      onClick={onClick}
    />
  )
}

const ProductGetNotifyInStockConnected = ({ product }: { product: Product | VariantProduct }) => {
  const addAlertNotificationOnProduct = useProductAlertCustomerNotifyInStock()
  const { addNotification } = useContext(AlertNotificationContext)
  const t = useTranslation()

  const onClick = async () => {
    if (!product.sku) {
      return
    }

    try {
      await addAlertNotificationOnProduct(product.sku)
      addNotification(t('catalog-product-alert-stock-confirmation-text', {}, false))
      gtmEvent(product)
    } catch (error) {
      const { message } = error as GraphQLError
      addNotification(message)
      handleError(error, false)
    }
  }

  return (
    <Button
      classname='black small'
      label={t('product_alert_popup_title', {}, false)}
      type='button'
      style='dark'
      onClick={onClick}
    />
  )
}

const ProductGetNotifyInStock = ({ product }: { product: Product | VariantProduct }) => {
  const { isLogged } = useContext(UserContext)

  if (isLogged) {
    return <ProductGetNotifyInStockConnected product={product} />
  }

  return <ProductGetNotifyInStockAsGuest product={product} />
}

export default ProductGetNotifyInStock
