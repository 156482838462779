import React, { useState, InputHTMLAttributes, ForwardRefRenderFunction } from 'react'
import Icon from '../../Icon/Icon'
import { Icons } from '../../Icon/constants'

type TextFieldProps = InputHTMLAttributes<HTMLInputElement> & {
  name: string
  label?: string
  error?: string
}

const TextWrapper: ForwardRefRenderFunction<HTMLInputElement, TextFieldProps> = (
  { className, label, error, type = 'text', ...rest },
  ref,
) => {
  const [inputType, setInputType] = useState(type)

  const togglePasswordVisibility = () => {
    setInputType((prevType) => (prevType === 'password' ? 'text' : 'password'))
  }

  return (
    <div className={`text-wrapper ${className ?? ''}`}>
      <input
        type={inputType}
        ref={ref}
        {...rest}
        id={rest.name}
        placeholder={rest.placeholder ?? ' '}
      />
      {label && (
        <label htmlFor={rest.name} className='text-wrapper-label'>
          {label}
        </label>
      )}
      {type === 'password' && (
        <button
          type='button'
          onClick={togglePasswordVisibility}
          className='toggle-password-visibility'>
          {inputType === 'password' ? (
            <Icon iconName={Icons.EYE_OFF} />
          ) : (
            <Icon iconName={Icons.EYE_EMPTY} />
          )}
        </button>
      )}
      {error && <span className='error'>{error}</span>}
    </div>
  )
}

export default React.forwardRef(TextWrapper)
