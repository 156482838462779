'use client'

import { useContext } from 'react'
import { ConfigContext } from '../context/ConfigContext'

export const useIsMobile = () => {
  const { deviceType } = useContext(ConfigContext)
  return deviceType === 'mobile'
}

export const useIsMobileOrTablet = () => {
  const { deviceType } = useContext(ConfigContext)
  return ['mobile', 'tablet'].includes(deviceType)
}

export const useIsTablet = () => {
  const { deviceType } = useContext(ConfigContext)
  return deviceType === 'tablet'
}
