import { MpProductAlertCustomerNotifyInStockDocument } from '../../queries/MpProductAlertCustomerNotifyInStock.gql'
import { MpProductAlertNotifyInStockDocument } from '../../queries/MpProductAlertNotifyInStock.gql'
import { useMutation } from './useMutation'

export type ProductAlertGuestNotifyInput = {
  email: string
  firstname: string
  lastname: string
  prefix: string
  productSku: string
}

export const useProductAlertGuestNotifyInStock = () => {
  const [mpProductAlertNotifyInStockMutation] = useMutation(MpProductAlertNotifyInStockDocument)

  const addAlertNotificationOnProduct = (input: ProductAlertGuestNotifyInput) =>
    mpProductAlertNotifyInStockMutation({ variables: { input } })

  return addAlertNotificationOnProduct
}

export const useProductAlertCustomerNotifyInStock = () => {
  const [mpProductAlertCustomerNotifyInStockMutation] = useMutation(
    MpProductAlertCustomerNotifyInStockDocument,
  )

  const addAlertNotificationOnProduct = (productSku: string) =>
    mpProductAlertCustomerNotifyInStockMutation({ variables: { productSku } })

  return addAlertNotificationOnProduct
}

export default useProductAlertGuestNotifyInStock
