'use client'

import { createContext, ReactNode } from 'react'
import { AlgoliaConfig } from '../../utils/AlgoliaConfigContext.utils'

type SearchProductContextType = {
  algoliaConfig: AlgoliaConfig | null
}

export const AlgoliaConfigContext = createContext<SearchProductContextType>({
  algoliaConfig: null,
})

type SearchProductContextProviderProps = SearchProductContextType & {
  children: ReactNode
}

const AlgoliaConfigProvider = ({ algoliaConfig, children }: SearchProductContextProviderProps) => (
    <AlgoliaConfigContext.Provider value={{ algoliaConfig }}>
      {children}
    </AlgoliaConfigContext.Provider>
  )

export default AlgoliaConfigProvider
