import { AssetType } from '../components/ProductAssets/ProductAssets.types'

export const getFileExtension = (src: string) => src.split('.').pop()

export const mimes = {
  videoMime: ['webm', 'ogv', 'mp4'],
  pictureMime: ['jpeg', 'jpg', 'png', 'gif', 'webp', 'tif', 'tiff', 'bmp'],
}

export const ROLE_SMALL = 'small_image'
export const ROLE_THUMBNAIL = 'thumbnail'
export const ROLE_FOCUS = 'focus'
export const ROLE_IMAGE = 'image'

export const convertImageToAssetType = (media: Image): AssetType => ({
  id: 0,
  type: 'image',
  src: media?.url ?? '',
  alt: media?.label ?? '',
  isIconique: 0,
  isNew: 0,
  isExcluWeb: 0,
  small_image: media?.small_image || '',
  thumbnail: media?.thumbnail || '',
  messika_mid_thumbnail: media?.messika_mid_thumbnail || '',
  messika_product_medium: media?.messika_product_medium || '',
  messika_product_large: media?.messika_product_large || '',
  roles: media?.roles || [],
})
