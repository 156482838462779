import { ApolloError } from '@apollo/client'
import { useCallback, useContext } from 'react'
import { CartContext } from '../../../presentation/context/CartContext'
import {
  addConfigurableProductsToCartDocument,
  ConfigurableProductCartItemInput,
} from '../../../queries/AddConfigurableProductsToCart.gql'
import { AddMyMoveToCartDocument } from '../../../queries/AddMyMoveToCart.gql'
import { addSimpleProductsToCartDocument } from '../../../queries/AddSimpleProductsToCart.gql'
import { useMutation } from '../useMutation'

const MY_MOVE_SKU = '3333'

export type AddMyMoveToCartOption = {
  option_id: number
  option_values: { attribute_id: number; value_index: number }[]
}

export const buildMyMoveOptionToCart = (
  product: Product,
  myMoveVariant: Variant,
  braceletMyMoveVariant: Variant,
) => {
  const options: AddMyMoveToCartOption[] = [
    {
      option_id: product.items?.[0].option_id,
      option_values: [
        {
          attribute_id: Number(
            product.items?.[0].options?.[0]?.product?.configurable_options?.[0].attribute_id,
          ),
          value_index: myMoveVariant.attributes?.[0]?.value_index ?? 0,
        },
      ],
    },
    {
      option_id: product.items?.[1].option_id,
      option_values: [
        {
          attribute_id: Number(
            product.items?.[1].options?.[0]?.product?.configurable_options?.[0].attribute_id,
          ),
          value_index: braceletMyMoveVariant.attributes?.[0]?.value_index ?? 0,
        },
        {
          attribute_id: Number(
            product.items?.[1].options?.[0]?.product?.configurable_options?.[1].attribute_id,
          ),
          value_index: braceletMyMoveVariant.attributes?.[1]?.value_index ?? 0,
        },
      ],
    },
  ]

  return options
}

const useAddMyMoveToCartQuery = () => {
  const [mutation] = useMutation(AddMyMoveToCartDocument)

  const addMyMoveToCartDocument = (
    cartId: string,
    options: AddMyMoveToCartOption[],
    sku: string = MY_MOVE_SKU,
    gtm_position: number,
    item_list_id: string,
    item_list_name: string,
  ) =>
    mutation({
      variables: {
        input: {
          cart_id: cartId,
          sku,
          gtm_position,
          item_list_id,
          item_list_name,
          options,
        },
      },
    })

  return [addMyMoveToCartDocument]
}

export const useAddMyMoveProductToCart = () => {
  const { cart, setCart, createCart } = useContext(CartContext)
  const [addMyMoveToCart] = useAddMyMoveToCartQuery()

  const addMyMoveProduct = useCallback(
    async (
      product: Product,
      options: AddMyMoveToCartOption[],
      gtm_position: number = 0,
      item_list_id: string,
      item_list_name: string,
    ) => {
      let cartId = cart.id

      if (cartId === null) {
        cartId = await createCart()
      }

      if (!cartId) {
        return {
          success: false,
          error: undefined,
        }
      }

      return addMyMoveToCart(
        cartId,
        options,
        product.sku ?? '',
        gtm_position ?? 0,
        item_list_id,
        item_list_name,
      )
        .then((data) => {
          if (data.data?.addMyMoveToCart?.cart) {
            // @ts-ignore
            setCart(data.data?.addMyMoveToCart.cart)

            return {
              success: true,
              error: undefined,
            }
          }

          throw new Error((data.errors && data.errors[0] && data.errors[0].message) || '')
        })
        .catch((e: ApolloError) => ({
          success: false,
          error: e.message,
        }))
    },
    [addMyMoveToCart, cart.id, createCart],
  )

  return addMyMoveProduct
}

const useAddProductToCart = () => {
  const { cart, setCart, createCart } = useContext(CartContext)
  const [addConfigurableProductsToCart] = useMutation(addConfigurableProductsToCartDocument)
  const [addSimpleProductsToCart] = useMutation(addSimpleProductsToCartDocument)

  const addProductToCart = useCallback(
    async (
      productToAdd: { sku?: string | null },
      carving?: Carving,
      parent?: { sku?: string | null },
      customizableOptions?: {
        id: number
        value_string: string
      },
      quantity = 1,
      gtm_position?: number,
      item_list_id?: string,
      item_list_name?: string,
    ) => {
      let cartId = cart.id

      if (cartId === null) {
        cartId = await createCart()
      }

      if (parent) {
        const cartItem: ConfigurableProductCartItemInput = {
          parent_sku: parent ? parent.sku : undefined,
          data: {
            quantity,
            sku: productToAdd.sku ?? '',
            gtm_position: gtm_position ?? 0,
            item_list_id: item_list_id ?? '',
            item_list_name: item_list_name ?? '',
            engraving_options: {
              has_engraving: !!carving,
              engraving_text: carving?.text,
              engraving_font: carving?.font,
            },
          },
        }

        if (customizableOptions) {
          cartItem.customizable_options = [customizableOptions]
        }

        return addConfigurableProductsToCart({
          variables: {
            cartId: cartId ?? '',
            cartItems: [cartItem],
          },
        })
          .then((data) => {
            if (data.data?.addConfigurableProductsToCart?.cart) {
              // @ts-ignore
              setCart(data.data?.addConfigurableProductsToCart?.cart)

              return {
                success: true,
                error: undefined,
              }
            }

            throw new Error((data.errors && data.errors[0] && data.errors[0].message) || '')
          })
          .catch((e: ApolloError) => ({
            success: false,
            error: e.message,
          }))
      }
      return addSimpleProductsToCart({
        variables: {
          cartId: cartId ?? '',
          cartItems: [
            {
              data: {
                quantity: 1,
                sku: productToAdd.sku ?? '',
                gtm_position: gtm_position ?? 0,
                item_list_id: item_list_id ?? '',
                item_list_name: item_list_name ?? '',
                engraving_options: {
                  has_engraving: !!carving,
                  engraving_text: carving?.text,
                  engraving_font: carving?.font,
                },
              },
            },
          ],
        },
      })
        .then((data): { success: true, error: undefined } | { success: false, error: string } => {
          if (data.data?.addSimpleProductsToCart?.cart) {
            // @ts-ignore
            setCart(data.data?.addSimpleProductsToCart?.cart)

            return {
              success: true,
              error: undefined,
            }
          }

          throw new Error((data.errors && data.errors[0] && data.errors[0].message) || '')
        })
        .catch(
          (e: ApolloError) =>
            ({
              success: false,
              error: e.message,
            }),
        )
    },
    [addConfigurableProductsToCart, addSimpleProductsToCart, cart.id, createCart],
  )

  return addProductToCart
}

export default useAddProductToCart
