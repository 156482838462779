'use client'

import { useSelect } from 'downshift'
import { useContext, useEffect } from 'react'
import { ConfigContext, useTranslation } from '../../../context/ConfigContext'
import { buildFullUrlMedia } from '../../../../core/hook/utils'
import Icon from '../../Icon/Icon'
import { Icons } from '../../Icon/constants'

export type CustomSelectProps<T> = {
  label?: string
  placeholder?: string
  list: {
    title: string
    value: T
    id: T
    disabled?: boolean
  }[]
  selectedValue?: T | countrySelected

  onChange: (value: T) => void
  hookedLink?: boolean
  typeHookedLink?: number | null
  error?: string
  className?: string
}
type countrySelected = {
  title: string
  value: string
  id: string
}

function CustomSelect<T>(props: CustomSelectProps<T>) {
  const { storeConfig } = useContext(ConfigContext)
  const t = useTranslation()
  const {
    isOpen,
    selectedItem,
    selectItem,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getItemProps,
    highlightedIndex,
  } = useSelect({
    items: props.list,
    itemToString: (item) => (item ? item.title : ''),
    selectedItem: props.list.find((item) => item.value === props.selectedValue),
    onSelectedItemChange: ({ selectedItem: newSelectedItem }) => {
      if (newSelectedItem) {
        props.onChange(newSelectedItem.value)
      }
    },
  })

  useEffect(() => {
    if (selectedItem?.value !== props.selectedValue) {
      const newItem = props.list.find((item) => item.value === props.selectedValue)
      selectItem(newItem ?? null)
    }
  }, [props.selectedValue, selectedItem])

  const switchSizeGuide = (id) => {
    switch (id) {
      case 4:
        return storeConfig?.catalog_size_guide_ring
      case 5:
        return storeConfig?.catalog_size_guide_necklace
      case 6:
        return storeConfig?.catalog_size_guide_bracelet
      default:
        return ''
    }
  }

  return (
    <div className={`block-select ${isOpen ? 'open' : ''} ${props.className ?? ''}`}>
      <div className='select-container' {...getToggleButtonProps()}>
        {props.label && <label {...getLabelProps()}>{props.label}</label>}
        <div className='placeholder' {...getLabelProps()}>
          {selectedItem ? (
            <div className='black'>{selectedItem.title}</div>
          ) : (
            <div className='grey'>{props.placeholder}</div>
          )}
        </div>
        <div className='right'>
          {props.hookedLink && (
            <a
              className='size-guide'
              target='_blank'
              rel='noreferrer'
              href={
                props.typeHookedLink ? buildFullUrlMedia(switchSizeGuide(props.typeHookedLink)) : ''
              }>
              {t('Sizes guide')}
            </a>
          )}
          <Icon iconName={Icons.NAV_ARROW_DOWN} className={`arrow ${isOpen ? 'rotate' : ''}`} />
        </div>
      </div>
      <ul className={`${isOpen ? 'open' : ''}`} {...getMenuProps()}>
        {isOpen && (
          <>
            {props.list.map((item, index) => {
              if (item.id !== selectedItem?.id) {
                return (
                  <li
                    className={`${item.disabled ? 'disabled-select' : ''} ${
                      highlightedIndex === index ? 'bold' : ''
                    } ${selectedItem === item ? 'bold' : ''}`}
                    key={`select-${item.id}`}
                    {...getItemProps({ item })}>
                    <span>{item.title}</span>
                  </li>
                )
              }
            })}
          </>
        )}
      </ul>
      {props.error && <span className='error'>{props.error}</span>}
    </div>
  )
}

export default CustomSelect
