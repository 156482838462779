import { CartItem as CartItemType } from '../../../domain/cart'
import { SubmitHandler, useForm } from 'react-hook-form'
import ProductConfigurationBundleProduct, {
  getSelectedVariantsForBundleProduct,
} from '../Product/ProductConfigurationBundleProduct'
import { useContext } from 'react'
import { CartListContext } from '../CartList/CartListContext'
import { useUpdateMyMoveProductFromCart } from '../../../core/hook/cartHooks/useUpdateProductFromCart'
import { buildMyMoveOptionToCart } from '../../../core/hook/cartHooks/useAddProductToCart'

type CartItemBundleMyMoveFormData = {
  myMoveColor: number
  braceletMyMoveSize: number
  braceletMyMoveColor?: number
}

export const getValuesFromBundleCartItem = (item: CartItemType) => ({
  myMoveColor: item.bundle_options?.[0]?.values?.[0]?.value_indices?.[0] ?? undefined,
  braceletMyMoveSize: item.bundle_options?.[1]?.values?.[0]?.value_indices?.[0] ?? undefined,
  braceletMyMoveColor: item.bundle_options?.[1]?.values?.[0]?.value_indices?.[1] ?? undefined,
})

const CartItemBundleMyMoveContainer = ({
  item,
  onUpdate,
}: {
  item: CartItemType
  onUpdate: (updating: boolean) => void
}) => {
  const updateMyMoveProductFromCart = useUpdateMyMoveProductFromCart()
  const { addProductOnUpdatingList, removeProductFromUpdatingList, updatingProducts } =
    useContext(CartListContext)
  const { setValue, watch, handleSubmit } = useForm<CartItemBundleMyMoveFormData>({
    defaultValues: getValuesFromBundleCartItem(item),
  })

  const selectedValues = watch()

  const onSubmit: SubmitHandler<CartItemBundleMyMoveFormData> = (data) => {
    const { myMoveColor, braceletMyMoveColor, braceletMyMoveSize } = data

    if (!myMoveColor || !braceletMyMoveColor || !braceletMyMoveSize) {
      return
    }

    const selectedVariantsForBundleMyMove = getSelectedVariantsForBundleProduct(
      item.product,
      myMoveColor,
      braceletMyMoveColor,
      braceletMyMoveSize,
    )

    if (!selectedVariantsForBundleMyMove) {
      return
    }

    const [myMoveVariant, braceletMyMoveVariant] = selectedVariantsForBundleMyMove

    if (!myMoveVariant || !braceletMyMoveVariant) {
      return
    }

    onUpdate(true)
    const options = buildMyMoveOptionToCart(item.product, myMoveVariant, braceletMyMoveVariant)

    updateMyMoveProductFromCart(item, item.product, options).then(() => {
      onUpdate(false)
    })

    if (updatingProducts.includes(item.id)) {
      removeProductFromUpdatingList(item.id)
    }
  }

  const onChangeMyMoveColor = (value?: number) => {
    if (value) {
      setValue('myMoveColor', value)
      handleSubmit(onSubmit)()
    }
  }
  const onChangeBraceletMyMoveSize = (value?: number) => {
    if (value) {
      setValue('braceletMyMoveSize', value)
      setValue('braceletMyMoveColor', undefined)
      addProductOnUpdatingList(item.id)
    }
  }
  const onChangeBraceletMyMoveColor = (value?: number) => {
    setValue('braceletMyMoveColor', value)
    handleSubmit(onSubmit)()
  }

  return (
    <div className='cart-item-form-configurable-product'>
      <ProductConfigurationBundleProduct
        product={item.product}
        myMoveColor={selectedValues.myMoveColor}
        braceletMyMoveSize={selectedValues.braceletMyMoveSize}
        braceletMyMoveColor={selectedValues.braceletMyMoveColor}
        onChange={(name, value) => {
          switch (name) {
            case 'myMoveColor':
              onChangeMyMoveColor(value)
              break
            case 'braceletMyMoveSize':
              onChangeBraceletMyMoveSize(value)
              break
            default:
              // braceletMyMoveColor
              onChangeBraceletMyMoveColor(value)
              break
          }
        }}
        disabledOptionsWhenNotInStock
      />
    </div>
  )
}

export default CartItemBundleMyMoveContainer
