'use client'

import { useContext, useState } from 'react'
import { ConfigContext, useTranslation } from '../../context/ConfigContext'
import { findVariant, getIsInStock, isSellingProductOnline } from '../../utils/Product.utils'
import {
  BRACELET_MY_MOVE_COLOR_ATTRIBUTE_NAME,
  BRACELET_MY_MOVE_SIZE_ATTRIBUTE_NAME,
} from './ProductConfiguration.utils'
import CustomSelect from '../Form/InputWrapper/CustomSelect'

type ProductConfigurationBraceletMyMoveProps = {
  product: Product
  size?: number
  typeExtended: number

  onChangeSize: (value: number) => void
  color?: number

  onChangeColor: (value: number) => void
  showOnlyAvailableColorBySize?: boolean
  disabledOptionsWhenNotInStock?: boolean
}

type ConfigurableOptionValues = {
  value_index?: number | null | undefined
  label?: string | null | undefined
} | null
type ConfigurableOptionValuesWithStockStatus = ConfigurableOptionValues & {
  isInStock: boolean
}

const filterSizeValues = (
  product: Product,
  values: ConfigurableOptionValues[],
  isEcommerce: boolean,
): ConfigurableOptionValuesWithStockStatus[] => {
  const filteredSizeValues = values.map((value) => {
    const isInStock =
      product.variants
        ?.filter((variant) =>
          variant?.attributes?.some((attribute) => attribute?.value_index === value?.value_index),
        )
        .some((variant) =>
          variant?.product
            ? getIsInStock(variant?.product) && isSellingProductOnline(variant.product, isEcommerce)
            : false,
        ) ?? false

    return {
      ...value,
      isInStock,
    }
  })

  return filteredSizeValues
}

const filterColorValuesWithStockStatus = (
  product: Product,
  values: ConfigurableOptionValues[],
  sizeValue: number,
  isEcommerce: boolean,
): ConfigurableOptionValuesWithStockStatus[] => {
  const results = values.map((value) => {
    if (!value?.value_index) {
      return {
        ...value,
        isInStock: false,
      }
    }
    const variant = findVariant(product, [
      { code: BRACELET_MY_MOVE_SIZE_ATTRIBUTE_NAME, value: sizeValue },
      { code: BRACELET_MY_MOVE_COLOR_ATTRIBUTE_NAME, value: value.value_index },
    ])

    if (!variant?.product) {
      return {
        ...value,
        isInStock: false,
      }
    }

    return {
      ...value,
      isInStock:
        getIsInStock(variant?.product) && isSellingProductOnline(variant.product, isEcommerce),
    }
  })

  return results
}

const filterColorValues = (
  product: Product,
  values: ConfigurableOptionValues[],
  sizeValue: number,
  isEcommerce: boolean,
  showOnlyAvailableColorBySize: boolean,
): ConfigurableOptionValuesWithStockStatus[] => {
  const filteredValues = filterColorValuesWithStockStatus(product, values, sizeValue, isEcommerce)
  return showOnlyAvailableColorBySize
    ? filteredValues.filter((value) => value.isInStock)
    : filteredValues
}

const ProductConfigurationBraceletMyMove = ({
  product,
  size,
  onChangeSize,
  color,
  onChangeColor,
  showOnlyAvailableColorBySize = false,
  disabledOptionsWhenNotInStock = false,
  typeExtended,
}: ProductConfigurationBraceletMyMoveProps) => {
  const { isEcommerce } = useContext(ConfigContext)
  const t = useTranslation()

  const configurableOptionSize = product.configurable_options?.find(
    (c) => c?.attribute_code === BRACELET_MY_MOVE_SIZE_ATTRIBUTE_NAME,
  )
  const configurableOptionColor = product.configurable_options?.find(
    (c) => c?.attribute_code === BRACELET_MY_MOVE_COLOR_ATTRIBUTE_NAME,
  )

  if (!configurableOptionSize?.values || !configurableOptionColor?.values) {
    return null
  }

  const filteredSizeValues = filterSizeValues(product, configurableOptionSize.values, isEcommerce())

  const filteredColorValues = size
    ? filterColorValues(
        product,
        configurableOptionColor.values,
        size,
        isEcommerce(),
        showOnlyAvailableColorBySize,
      )
    : []

  const [onSelect, setOnSelect] = useState(false)

  const onChangeSelect = () => {
    setOnSelect(true)
  }

  return (
    <>
      <CustomSelect
        label={configurableOptionSize.label ?? ''}
        onChange={(valueIndex) => {
          onChangeSize(valueIndex)
          onChangeSelect()
        }}
        selectedValue={size}
        hookedLink
        typeHookedLink={typeExtended}
        list={
          filteredSizeValues.map((value) => ({
            title: `${value?.label} ${
              !value?.isInStock ? ` - ${t('Not available', {}, false)}` : ''
            }`,
            value: value.value_index ?? 0,
            id: value.value_index ?? 0,
            disabled: disabledOptionsWhenNotInStock && !value.isInStock,
          })) ?? []
        }
      />
      <CustomSelect
        className={`${onSelect ? 'active' : 'disabled'}`}
        label={configurableOptionColor.label ?? ''}
        onChange={(valueIndex) => {
          onChangeColor(valueIndex)
        }}
        selectedValue={color}
        list={
          filteredColorValues.map((value) => ({
            title: `${value?.label} ${
              !value?.isInStock ? ` - ${t('Not available', {}, false)}` : ''
            }`,
            value: value.value_index ?? 0,
            id: value?.value_index ?? 0,
            disabled: disabledOptionsWhenNotInStock && !value.isInStock,
          })) ?? []
        }
      />
    </>
  )
}

export default ProductConfigurationBraceletMyMove
