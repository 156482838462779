import { useContext } from 'react'
import { Cart, CartItem } from '../../../domain/cart'
import { AlertNotificationContext } from '../../../presentation/context/AlertNotificationContext'
import { AddMyMoveToCartOption } from './useAddProductToCart'
import { useMutation } from '../useMutation'
import { UpdateMyMoveCartItemDocument } from '../../../queries/UpdateMyMoveCartItem.gql'
import { CartContext } from '../../../presentation/context/CartContext'
import { ApolloError } from '@apollo/client'
import { UpdateConfigurableCartItemDocument } from '../../../queries/UpdateConfigurableCartItem.gql'
import { UltranoirOptionValues } from '../../../../.mesh'

const useUpdateProductFromCart = () => {
  const [updateProductToCart] = useMutation(UpdateConfigurableCartItemDocument)
  const { addNotification } = useContext(AlertNotificationContext)
  const { cart, setCart } = useContext(CartContext)

  const updateProductFromCart = async (
    item: CartItem,
    carving?: Carving,
    options?: UltranoirOptionValues[],
    customizableOptions?: {
      id: number
      value_string: string
    }[],
  ) => {
    if (!cart.id) {
      return
    }

    return updateProductToCart({
      variables: {
        input: {
          cart_id: cart.id,
          cart_item_id: parseInt(item.id.toString()),
          options,
          engraving_options: {
            engraving_font: carving?.font,
            engraving_text: carving?.text,
            has_engraving: !!carving,
          },
          customizable_options: customizableOptions,
        },
      },
    })
      .then((response) => {
        if (response.errors && response.errors.length > 0) {
          throw new Error(response.errors[0].message)
        }
        //@ts-ignore
        return setCart(response.data?.updateConfigurableCartItem.cart as Cart)
      })
      .catch((e: ApolloError) => addNotification(e.message || ''))
  }

  return updateProductFromCart
}

export const useUpdateMyMoveProductFromCart = () => {
  const [updateMyMoveProductToCart] = useMutation(UpdateMyMoveCartItemDocument)
  const { addNotification } = useContext(AlertNotificationContext)
  const { cart, setCart } = useContext(CartContext)

  const updateMyMoveProduct = async (
    itemToRemove: CartItem,
    product: Product,
    options: AddMyMoveToCartOption[],
  ) => {
    if (!cart.id) {
      return
    }

    return updateMyMoveProductToCart({
      variables: {
        input: {
          cart_id: cart.id,
          cart_item_id: itemToRemove.id,
          sku: product.sku,
          options: options,
        },
      },
    })
      .then((response) => {
        if (response.errors && response.errors.length > 0) {
          throw new Error(response.errors[0].message)
        }
        if (response.data?.updateMyMoveCartItem?.cart) {
          //@ts-ignore
          setCart(response.data.updateMyMoveCartItem.cart as Cart)
        }

        return
      })
      .catch((e: ApolloError) => addNotification(e.message || ''))
  }

  return updateMyMoveProduct
}

export default useUpdateProductFromCart
