'use client'
import { FunctionComponent, useContext } from 'react'
import { Cart, CartItem as CartItemType } from '../../../domain/cart'
import { ModalContext, ModalContextType } from '../../context/ModalContext'
import { Button } from '../Button/Button'
import { Modal } from './Modal'
import { gtmCategory, gtmPush } from '../../utils/gtmHelpers'
import useRemoveProductFromCart from '../../../core/hook/cartHooks/useRemoveProductFromCart'
import { useTranslation } from '../../context/ConfigContext'
import Image from '../Image/Image'
import { getImageToShowCartItem } from '../CartItem/CartItem.utils'
import { AlertNotificationContext } from '../../context/AlertNotificationContext'

export const useOnRemoveProductFromCart = () => {
  const { addModal, removeModal } = useContext(ModalContext) as ModalContextType
  const { addNotification } = useContext(AlertNotificationContext)
  const removeProductFromCart = useRemoveProductFromCart()

  const gtmEvent = (cart: Cart, item: CartItemType) => {
    const gtmCategoryObj = gtmCategory(item.product.gtm_categories)

    const cartValue = item?.prices?.price_including_tax?.value
      ? cart?.prices?.grand_total?.value - item?.prices?.price_including_tax?.value
      : cart?.prices?.grand_total?.value

    const productSize = item?.configurable_options?.[0]?.value_label ?? null
    const sizeSku = productSize ? item?.product.sku + '-' + productSize : item?.product.sku

    gtmPush({
      event: 'remove_from_cart',
      ecommerce: {
        currency: item?.prices?.row_total?.currency,
        value: cartValue,
        items: [
          {
            item_name: item.product.gtm_name,
            item_id: item.product.sku,
            price: item.product.price_range.minimum_price.regular_price.value,
            item_brand: 'Messika',
            index: item?.gtm_position,
            item_list_id: item.item_list_id ?? 'panier',
            item_list_name: item.item_list_name ?? 'Panier',
            item_variant: sizeSku,
            ...gtmCategoryObj,
          },
        ],
      },
    })
  }

  const onRemoveProductFromCart = (
    cart: Cart,
    item: CartItemType,
    callback?: (cartItemId: number) => void,
  ) => {
    const uuid = crypto.randomUUID()
    addModal({
      uuid,
      component: (
        <ModalDeleteProduct
          uuid={uuid}
          item={item}
          onClick={() => {
            gtmEvent(cart, item)
            removeProductFromCart(item).then(
              (response) => !response.success && response.error && addNotification(response.error),
            )
            callback?.(item.id)
            removeModal(uuid)
          }}
          onClose={() => {
            removeModal(uuid)
          }}
        />
      ),
    })
  }

  return onRemoveProductFromCart
}

interface ModalDeleteProductProps extends ModalInterface {
  item: CartItemType
  onClick: () => void
}

export const ModalDeleteProduct: FunctionComponent<ModalDeleteProductProps> = ({
  uuid,
  onClose,
  onClick,
  item,
}) => {
  const t = useTranslation()

  const imageToShow = getImageToShowCartItem(item)

  return (
    <Modal uuid={uuid} dark={false} showLogo={false} center anchor='center' onClose={onClose}>
      <div className='modal-delete-product'>
        <picture>
          <Image src={imageToShow?.thumbnail} alt={item.product.name} size='s' />
        </picture>
        <p>
          {t('Are you sure you would like to remove this item from the shopping cart?', {}, true)}
        </p>
        <div className='button-group'>
          <Button classname='black' label={t('Remove', {}, false)} style='dark' onClick={onClick} />
          <Button
            classname='border-black'
            label={t('cart-popin-cancel-text', {}, false)}
            style='light'
            onClick={onClose}
          />
        </div>
      </div>
    </Modal>
  )
}
