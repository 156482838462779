import { HtmlContent } from '../../domain/htmlContent'
import { SelectionItem } from '../../domain/selectionItem'
import { ROLE_IMAGE } from './media.utils'

export const findVariant = (
  product: Product,
  valuesAttributes: { code: string; value: number }[],
): Variant | null => {
  const selectedVariant = product.variants?.find((variant) => {
    // Il faut que le variant ai le meme nombre d'attribut que celles selectionnés et que tout les attributs selectionnés soit présent dans le variant
    const attributesAreAllSelected = valuesAttributes.every((item) => {
      const attribute = variant?.attributes?.find(
        (a) => a?.code === item.code && a.value_index === item.value,
      )
      return !!attribute
    })

    return attributesAreAllSelected
  })

  return selectedVariant ?? null
}

export const getIsBraceletMyMove = (product: Product) =>
  !!(
    product.__typename === 'ConfigurableProduct' &&
    product.configurable_options &&
    product.configurable_options.length > 1
  )
export const getIsMyMove = (product: Product) => !!(product.__typename === 'BundleProduct')

export const SELLING_PRODUCT_ONLINE_YES = 125
export const SELLING_PRODUCT_ONLINE_NO = 126

export const isSellingProductOnline = (product: Product | VariantProduct, isEcommerce = true) => {
  if (!isEcommerce) {
    return false
  }

  // en_vente === 126 not selling this product online
  // en_vente === 125 selling this product online
  return product.en_vente === SELLING_PRODUCT_ONLINE_YES
}

export const getIsInStock = (product: Product | VariantProduct) =>
  product?.stock_status === 'IN_STOCK'

export const getIsAtLeastOneVariantInStock = (variants: Array<Variant | null>) =>
  variants.some((variant) => !!(variant?.product && getIsInStock(variant.product)))

export const getIsAtLeastOneVariantIsSellingAndInStock = (
  variants: Array<Variant | null>,
  isEcommerce = true,
) =>
  variants.some(
    (variant) =>
      !!(
        variant?.product &&
        getIsInStock(variant.product) &&
        isSellingProductOnline(variant.product, isEcommerce)
      ),
  )

export const getFirstImageOfProductByPosition = (product: Product) => {
  if (product.media_gallery && product.media_gallery.length > 0) {
    const firstImg = product.media_gallery.find((mg) => mg?.position === 0)
    return firstImg ?? product.media_gallery[0]
  }

  return null
}

export const sortImageGalleryOfProduct = (product: Product) => {
  if (!product.media_gallery) {
    return []
  }

  // BY POSITION
  const mediaGallerySortByPosition = [...product.media_gallery].sort(
    (a, b) => (a?.position ?? 0) - (b?.position ?? 0),
  )

  return sortProductMediaGalleryByRoles(mediaGallerySortByPosition, [ROLE_IMAGE])
}

export const getMyMoveCustomImage = (
  product: Product,
  selectedVariantsForBundleMyMove: (Variant | null)[],
) => {
  const [myMoveVariant, braceletMyMoveVariant] = selectedVariantsForBundleMyMove

  if (!myMoveVariant || !braceletMyMoveVariant) {
    return undefined
  }

  const skuMyMoveVariant = myMoveVariant.product?.sku?.toLowerCase()
  const skuBraceletMyMoveVariant = braceletMyMoveVariant.product?.sku?.split('-')[0]

  if (!skuMyMoveVariant || !skuBraceletMyMoveVariant) {
    return undefined
  }

  const media = product.media_gallery?.find(
    (mg) => mg?.url?.includes(skuMyMoveVariant) && mg?.url?.includes(skuBraceletMyMoveVariant),
  )

  return media
}

export const getUrlOfProductLinkOption = (productLinkOption: ProductLinkOption | null) => {
  if (productLinkOption?.product?.url_key) {
    return `/${productLinkOption?.product?.url_key}`
  }

  return productLinkOption?.url ?? ''
}

export const mapCmsProductsToProducts = (cmsProducts: Pick<NonNullable<Product>, 'items'>) => {
  const customProducts: SelectionItem[] = []

  cmsProducts?.items?.forEach((item) => {
    if (!item) {
      return
    }

    customProducts.push({
      id: item.id || 0,
      sku: item.sku || '',
      name: item.name || '',
      gtm_name: item.gtm_name || '',
      titleDescription: item.title || '',
      urlKey: item.url_key || '',
      colorVariations: item.color_variations || 0,

      gtm_categories: item.gtm_categories || '',

      mediaGallery: sortImageGalleryOfProduct(item as Product) as Image[],

      shortDescription: item.short_description as HtmlContent,
      priceRange: item.price_range as PriceRange,

      stockStatus: item.stock_status || '',
      enVente: item.en_vente || 0,

      isIconique: item.isIconique || 0,
      isNew: item.isNew || 0,
      isExcluWeb: item.isExcluWeb || 0,

      typename: item.__typename || '',
      customPrice: item.display_custom_price || 0,
      mariage: item.is_marriage || 0,

      couleurMetalExtended: item.couleur_metal_extended as couleurMetalExtended,

      categoriesOfProduct: item.type_extended as CategoriesFilters,

      collectionProductExtended: item.collection_product_extended as CollectionProductFilter,
      variants: (item as Product).variants,
    })
  })

  return customProducts
}

export const sortProductMediaGalleryByRoles = (mediaGallery: Image[], sort: string[]) => {
  sort.reverse().map((sortType) => {
    mediaGallery.sort((a) => {
      if (a.roles && a.roles.includes(sortType)) {
        return -1
      }
      return 0
    })
  })

  return mediaGallery
}

export const findOneProductMediaGalleryByRole = (
  mediaGallery: Image[],
  roles: string,
): Image | null =>
  (mediaGallery.length > 0 &&
    (mediaGallery.find((media) => media.roles?.includes(roles)) || mediaGallery[0])) ||
  null
