'use client'

import clsx from 'clsx'
import { useTranslation } from '../../context/ConfigContext'

interface TagProps {
  isExcluWeb: number
  isNew: number
  isIconique: number
  className?: string
}

export const Tag = (props: TagProps) => {
  const t = useTranslation()

  const className = clsx('push-tag', props.className)

  return (
    <>
      {/* Exclu Web > New > Iconique */}
      {props.isExcluWeb === 1 ? (
        <p className={className}>{t('catalog-listing-exclu-web')}</p>
      ) : // isExcluWeb !== 1
      props.isNew === 1 ? (
        <p className={className}>{t('catalog-listing-new')}</p>
      ) : // isExcluWeb && isNew !== 1
      props.isIconique === 1 ? (
        <p className={className}>{t('catalog-listing-iconique')}</p>
      ) : null}
    </>
  )
}
