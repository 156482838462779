'use client'

import { FunctionComponent, useContext } from 'react'
import { Cart } from '../../../domain/cart'
import CartItem, { CartItemSmall } from '../CartItem/CartItem'
import { useOnRemoveProductFromCart } from '../Modal/ModalDeleteProduct'
import { CartListContext } from './CartListContext'

export const CartListSmall = ({ cart, allowRemove = true }) => {
  const onRemoveProductFromCart = useOnRemoveProductFromCart()

  return (
    <section className='cart-list'>
      {cart.items.map((item) => (
        <CartItemSmall
          key={item.id}
          item={item}
          allowRemove={allowRemove}
          onRemove={() => onRemoveProductFromCart(cart, item)}
        />
      ))}
    </section>
  )
}

type CartListProps = {
  cart: Cart
}

const CartList: FunctionComponent<CartListProps> = ({ cart }) => {
  const onRemoveProductFromCart = useOnRemoveProductFromCart()
  const { removeProductFromUpdatingList } = useContext(CartListContext)

  return (
    <section className='cart-list'>
      {cart.items.map((item) => (
        <CartItem
          key={item.id}
          item={item}
          onRemove={() => onRemoveProductFromCart(cart, item, removeProductFromUpdatingList)}
        />
      ))}
    </section>
  )
}

export default CartList
