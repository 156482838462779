import React from 'react'
import Image from '../Image/Image'
import clsx from 'clsx'

interface LoaderProps {
  small?: boolean
  fullPage?: boolean
}

const Loader = ({ small, fullPage = false }: LoaderProps) => (
  <div className={clsx('loader', { 'loader--small': small, 'loader--fullpage': fullPage })}>
    <div className={'loader__container'}>
      <Image src='/assets/img/loader.gif' alt='Loader loop animation' width={399} height={405} />
    </div>
  </div>
)

export default Loader
