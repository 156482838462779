import { useMemo } from 'react'
import useUpdateCarvingOfCartItem from '../../../core/hook/cartHooks/useUpdateCarvingOfCartItem'
import useUpdateProductFromCart from '../../../core/hook/cartHooks/useUpdateProductFromCart'
import { CartItem as CartItemType } from '../../../domain/cart'
import { findVariant } from '../../utils/Product.utils'
import getIsItemStillValidOnCart from '../CheckoutWorkflow/getIsItemStillValidOnCart'
import ProductConfigurationSelectConfigurableOption from '../Product/ProductConfigurationSelectConfigurableOption'
import CartItemCarving from './CartItemCarving'
import { UltranoirOptionValues } from '../../../../.mesh'

const CartItemConfigurableProduct = ({
  item,
  onUpdate,
}: {
  item: CartItemType
  onUpdate: (updating: boolean) => void
}) => {
  const updateProductFromCart = useUpdateProductFromCart()
  const updateCarving = useUpdateCarvingOfCartItem()

  const isItemStillValidOnCart = useMemo(() => getIsItemStillValidOnCart(item), [item])

  const onChangeConfigurableOption = (value: number) => {
    const configurableOption = item.product.configurable_options?.[0]
    if (!configurableOption?.attribute_code) {
      return
    }

    const variant = findVariant(item.product, [{ value, code: configurableOption.attribute_code }])

    if (!variant?.product) {
      return
    }

    const carving: Carving | undefined = item.engraving_options?.has_engraving
      ? {
          font: item.engraving_options.engraving_font,
          text: item.engraving_options.engraving_text,
        }
      : undefined

    onUpdate(true)
    updateProductFromCart(item, carving, [
      {
        attribute_id: configurableOption.attribute_id,
        value_index: value,
      } as UltranoirOptionValues,
    ]).then(() => onUpdate(false))
  }

  const onChangeCarvingOnConfigurableProduct = (carving?: Carving) => {
    const codeAttribute = item.product.configurable_options?.[0]?.attribute_code
    const valueId = item.configurable_options[0].value_id

    if (!codeAttribute || !valueId) {
      return
    }

    const variant = findVariant(item.product, [{ value: valueId, code: codeAttribute }])

    if (!variant?.product) {
      return
    }

    updateCarving(item, carving)

    return
  }

  return (
    <div
      className={`cart-item-form-configurableproduct cart-item-without-size-guide cart-item-form-configurableproduct-id-${item.id}`}>
      <ProductConfigurationSelectConfigurableOption
        product={item.product}
        value={Number(item.configurable_options?.[0].value_id)}
        onChange={onChangeConfigurableOption}
        disabledOptionsWhenNotInStock
      />
      {isItemStillValidOnCart && (
        <CartItemCarving item={item} onChange={onChangeCarvingOnConfigurableProduct} />
      )}
    </div>
  )
}

export default CartItemConfigurableProduct
