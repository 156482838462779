import { FunctionComponent } from 'react'
import ImageNext from 'next/image'

const sizes = {
  xs: { width: 24, height: 24 },
  s: { width: 168, height: 168 },
  m: { width: 326, height: 326 },
  l: { width: 500, height: 500 },
  xl: { width: 900, height: 900 },
}

type ImageProps = {
  src?: string | null
  alt?: string | null
  width?: number
  height?: number
  size?: 'xs' | 's' | 'm' | 'l' | 'xl'
  className?: string
  priority?: boolean
}
const Image: FunctionComponent<ImageProps> = (props) => {
  const {
    src,
    alt,
    width,
    height,
    size = 'm',
    className,
    priority = undefined,
  } = props;
  let selectedSize = sizes[size]

  if (width && height) {
    selectedSize = { width, height }
  }

  return (
    <ImageNext
      unoptimized={!!process.env.NEXT_PUBLIC_IMAGE_UNOPTIMIZED}
      alt={alt ?? ''}
      src={src ?? ''}
      loading={priority ? undefined : 'lazy'}
      quality={85}
      priority={priority}
      {...selectedSize}
      className={className ?? ''}
      placeholder='blur'
      blurDataURL='/assets/icons/icon-loader.svg'
    />
  )
}

export default Image
