'use client'

import useCurrentLocale from '../../../core/hook/useCurrentLocale'
import { useTranslation } from '../../context/ConfigContext'

export const getPriceLocale = (locale: string) => {
  switch (locale) {
    case 'fr':
    case 'en':
      return 'fr-FR'
    case 'de':
      return 'de-DE'
    case 'es':
      return 'es-ES'
    case 'sa_en':
      return 'en-SA'
    case 'uk_en':
      return 'en-GB'
    case 'us_en':
      return 'en-US'
    case 'su_en':
      return 'en-CH'
    case 'su_fr':
      return 'fr-CH'
    case 'ar_ar':
    case 'ar_en':
      return 'ar-AE'
    case 'sa_ar':
      // 'ar-SA' return eastern arabic numeral. We want to show arabic numbers.
      return 'ar-AE'
      // return 'ar-SA'
    case 'jp_ja':
      return 'ja-JP'
    case 'ch_cn':
      return 'zh-CN'
    case 'hk_en':
      return 'zh-HK'
    case 'ru_en':
    case 'ru_ru':
      return 'ru-RU'
    case 'tu_en':
      return 'tr-TR'
    case 'kr_kr':
      return 'ko'
    default:
      return 'fr-FR'
  }
}

export default function FormattedPrice(props: {
  value?: number | null
  price?: number | null
  currency?: string | null
  locale?: string | null
  numeric?: boolean
}) {
  const price = props.value ?? props.price ?? 0
  const currency = props.currency ?? 'EUR'
  const currentLocale = useCurrentLocale()
  const { numeric } = props
  const priceLocale = getPriceLocale(props.locale ?? currentLocale)
  const t = useTranslation()
  if (price === 0 && !numeric) {
    return t('Offered')
  }
  return Intl.NumberFormat(priceLocale, {
    style: 'currency',
    currency,
    maximumFractionDigits: Number.isInteger(price) ? 0 : 2,
  }).format(price)
}
