import { findVariant } from '../../utils/Product.utils'
import {
  BRACELET_MY_MOVE_COLOR_ATTRIBUTE_NAME,
  BRACELET_MY_MOVE_SIZE_ATTRIBUTE_NAME,
  MY_MOVE_COLOR_ATTRIBUTE_NAME,
} from './ProductConfiguration.utils'
import ProductConfigurationBraceletMyMove from './ProductConfigurationBraceletMyMove'
import ProductConfigurationSelectConfigurableOption from './ProductConfigurationSelectConfigurableOption'

export const getSelectedVariantsForBundleProduct = (
  product: Product,
  myMoveColor: number,
  braceletMyMoveColor: number,
  braceletMyMoveSize: number,
) => {
  const myMoveProduct = product.items?.[0]?.options?.[0]?.product as Product
  const braceletMyMoveProduct = product.items?.[1]?.options?.[0]?.product as Product

  const myMoveVariant = findVariant(myMoveProduct, [
    { code: MY_MOVE_COLOR_ATTRIBUTE_NAME, value: myMoveColor },
  ])

  const braceletMyMoveVariant = findVariant(braceletMyMoveProduct, [
    { code: BRACELET_MY_MOVE_SIZE_ATTRIBUTE_NAME, value: braceletMyMoveSize },
    { code: BRACELET_MY_MOVE_COLOR_ATTRIBUTE_NAME, value: braceletMyMoveColor },
  ])

  return [myMoveVariant, braceletMyMoveVariant]
}

type ProductConfigurationBundleProductProps = {
  product: Product
  myMoveColor?: number
  braceletMyMoveSize?: number
  braceletMyMoveColor?: number
  onChange: (
    name: 'myMoveColor' | 'braceletMyMoveSize' | 'braceletMyMoveColor',

    value?: number,
  ) => void
  disabledOptionsWhenNotInStock?: boolean
}

const ProductConfigurationBundleProduct = ({
  product,
  myMoveColor,
  braceletMyMoveSize,
  braceletMyMoveColor,
  onChange,
  disabledOptionsWhenNotInStock = false,
}: ProductConfigurationBundleProductProps) => (
  <>
    {product.items?.[0]?.options?.[0]?.product && (
      <ProductConfigurationSelectConfigurableOption
        product={product.items?.[0]?.options?.[0]?.product as Product}
        value={myMoveColor}
        onChange={(value) => onChange('myMoveColor', value)}
        disabledOptionsWhenNotInStock={disabledOptionsWhenNotInStock}
      />
    )}
    {product.items?.[1]?.options?.[0]?.product && (
      <ProductConfigurationBraceletMyMove
        typeExtended={product.type_extended?.id ?? 0}
        product={product.items?.[1]?.options?.[0]?.product as Product}
        size={braceletMyMoveSize}
        onChangeSize={(value) => {
          onChange('braceletMyMoveSize', value)
          onChange('braceletMyMoveColor', undefined)
        }}
        color={braceletMyMoveColor}
        onChangeColor={(value) => onChange('braceletMyMoveColor', value)}
        disabledOptionsWhenNotInStock={disabledOptionsWhenNotInStock}
      />
    )}
  </>
)

export default ProductConfigurationBundleProduct
