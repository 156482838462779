import { Cart, CartItem } from '../../../domain/cart'
import {
  findVariant,
  getIsBraceletMyMove,
  getIsInStock,
  getIsMyMove,
} from '../../utils/Product.utils'
import {
  BRACELET_MY_MOVE_COLOR_ATTRIBUTE_NAME,
  BRACELET_MY_MOVE_SIZE_ATTRIBUTE_NAME,
} from '../Product/ProductConfiguration.utils'
import { getSelectedVariantsForBundleProduct } from '../Product/ProductConfigurationBundleProduct'

const getIsBundleMyMoveStillValidOnCart = (item: CartItem) => {
  const myMoveColor = item.bundle_options?.[0]?.values?.[0]?.value_indices?.[0] ?? undefined
  const braceletMyMoveSize = item.bundle_options?.[1]?.values?.[0]?.value_indices?.[0] ?? undefined
  const braceletMyMoveColor = item.bundle_options?.[1]?.values?.[0]?.value_indices?.[1] ?? undefined

  if (!myMoveColor || !braceletMyMoveColor || !braceletMyMoveSize) {
    return false
  }

  const selectedVariantsForBundleMyMove = getSelectedVariantsForBundleProduct(
    item.product,
    myMoveColor,
    braceletMyMoveColor,
    braceletMyMoveSize,
  )

  if (!selectedVariantsForBundleMyMove) {
    return false
  }

  const [myMoveVariant, braceletMyMoveVariant] = selectedVariantsForBundleMyMove

  if (!myMoveVariant?.product || !braceletMyMoveVariant?.product) {
    return false
  }

  return getIsInStock(myMoveVariant.product) && getIsInStock(braceletMyMoveVariant.product)
}

const getIsBraceletMyMoveStillValidOnCart = (item: CartItem) => {
  const selectedVariant = findVariant(item.product, [
    {
      code: BRACELET_MY_MOVE_SIZE_ATTRIBUTE_NAME,
      value: item.configurable_options[0].value_id,
    },
    {
      code: BRACELET_MY_MOVE_COLOR_ATTRIBUTE_NAME,
      value: item.configurable_options[1].value_id,
    },
  ])

  if (!selectedVariant?.product) {
    return false
  }

  return getIsInStock(selectedVariant.product)
}

const getIsConfigurableProductStillValidOnCart = (item: CartItem) => {
  const attributeCode = item.product.configurable_options?.[0]?.attribute_code
  if (!attributeCode) {
    return false
  }

  const selectedVariant = findVariant(item.product, [
    {
      code: attributeCode,
      value: item.configurable_options[0].value_id,
    },
  ])

  if (!selectedVariant?.product) {
    return false
  }

  return getIsInStock(selectedVariant.product)
}

const getIsItemStillValidOnCart = (cartItem: CartItem) => {
  // Simple Product
  if (cartItem.product.__typename === 'SimpleProduct') {
    return getIsInStock(cartItem.product)
  }

  if (cartItem.product.__typename === 'ConfigurableProduct') {
    // Bracelet My Move
    if (getIsBraceletMyMove(cartItem.product)) {
      return getIsBraceletMyMoveStillValidOnCart(cartItem)
    }

    // Configurable Product
    return getIsConfigurableProductStillValidOnCart(cartItem)
  }

  // Bundle My move
  if (getIsMyMove(cartItem.product)) {
    return getIsBundleMyMoveStillValidOnCart(cartItem)
  }

  return true
}

export const getAreAllItemsStillValidOnCart = (cart: Cart) => cart.items.every((cartItem) => getIsItemStillValidOnCart(cartItem))

export default getIsItemStillValidOnCart
