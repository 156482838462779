import React, { SelectHTMLAttributes } from 'react'
import Icon from '../../Icon/Icon'
import { Icons } from '../../Icon/constants'

type SelectWrapperProps = SelectHTMLAttributes<HTMLSelectElement> & {
  name: string
  label?: string
  style?: 'default' | 'inline'
  error?: string
}

const SelectWrapper = React.forwardRef<HTMLSelectElement, SelectWrapperProps>(
  ({ className, label, error, style = 'default', ...rest }, ref) => (
    <div className={`select-wrapper ${className} ${style}`}>
      {label && (
        <label htmlFor={rest.name} className='select-wrapper-label'>
          {label}
        </label>
      )}
      <select ref={ref} {...rest} id={rest.name}>
        {rest.children}
      </select>
      <Icon iconName={Icons.NAV_ARROW_DOWN} className='select-wrapper-icon' />
      {error && <span className='error'>{error}</span>}
    </div>
  ),
)

export default SelectWrapper
