'use client'

import {
  QueryHookOptions,
  QueryResult,
  TypedDocumentNode,
  useQuery as useApolloQuery,
} from '@apollo/client'

import { DocumentNode } from 'graphql'
import useCurrentLocale from './useCurrentLocale'
import { getCookie } from 'cookies-next'

export const useQuery = <TData = unknown>(
  query: DocumentNode | TypedDocumentNode<TData>,
  options?: QueryHookOptions,
): QueryResult<TData> => {
  const locale = useCurrentLocale()

  const userToken = getCookie('userToken')
  const queryResult = useApolloQuery(query, {
    variables: {
      ...options?.variables,
    },
    context: {
      headers: {
        ...options?.context?.headers,
        Store: locale,
        Authorization: userToken ? `Bearer ${userToken}` : null,
      },
    },
    fetchPolicy: options?.fetchPolicy ?? 'no-cache',
  })

  return queryResult
}
