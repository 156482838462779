import { ApolloError } from '@apollo/client'
import { useCallback, useContext } from 'react'
import { CartItem } from '../../../domain/cart'
import { CartContext } from '../../../presentation/context/CartContext'
import { RemoveItemFormCartDocument } from '../../../queries/RemoveItemFromCart.gql'
import { useMutation } from '../useMutation'

const useRemoveProductFromCart = () => {
  const { cart, setCart } = useContext(CartContext)
  const [removeItemFromCart] = useMutation(RemoveItemFormCartDocument)

  const removeProductFromCart = useCallback(
    async (cartItemToRemove: CartItem): Promise<{ success: boolean; error?: string }> =>
      removeItemFromCart({
        variables: {
          cartId: cart.id ?? '',
          itemId: Number(cartItemToRemove.id),
        },
      })
        .then((data) => {
          if (data.data?.removeItemFromCart?.cart) {
            // @ts-ignore
            setCart(data.data?.removeItemFromCart?.cart)

            return {
              success: true,
            }
          }

          throw new Error((data.errors && data.errors[0] && data.errors[0].message) || '')
        })
        .catch((e: ApolloError) => ({
          success: false,
          error: e.message,
        })),
    [cart.id, removeItemFromCart],
  )

  return removeProductFromCart
}

export default useRemoveProductFromCart
