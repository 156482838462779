'use client'

import { createContext, ReactNode, useMemo, useState } from 'react'

export type CartListContextType = {
  updatingProducts: number[]
  addProductOnUpdatingList: (itemId: number) => void
  removeProductFromUpdatingList: (itemId: number) => void
}

export const CartListContext = createContext<CartListContextType>({
  updatingProducts: [],
  addProductOnUpdatingList: () => {},
  removeProductFromUpdatingList: () => {},
})

const CartListProvider = ({ children }: { children: ReactNode }) => {
  const [updatingProducts, setUpdatingProducts] = useState<number[]>([])

  const addProductOnUpdatingList = (itemId: number) => {
    setUpdatingProducts((prevState) =>
      !prevState.includes(itemId) ? [...prevState, itemId] : prevState,
    )
  }

  const removeProductFromUpdatingList = (itemId: number) => {
    setUpdatingProducts((prevState) => prevState.filter((p) => p !== itemId))
  }

  const context = useMemo(
    () => ({
      updatingProducts,
      addProductOnUpdatingList,
      removeProductFromUpdatingList,
    }),
    [updatingProducts, addProductOnUpdatingList, removeProductFromUpdatingList],
  )

  return <CartListContext.Provider value={context}>{children}</CartListContext.Provider>
}

export default CartListProvider
