import { useCallback } from 'react'
import { CartItemUpdateInput, UpdateCartItemsDocument } from '../../../queries/UpdateCartItems.gql'
import { useMutation } from '../useMutation'

const useUpdateCartItems = () => {
  const [mutation] = useMutation(UpdateCartItemsDocument)

  const updateCartItems = useCallback(
    (cartId: string, cartItem: CartItemUpdateInput) =>
      mutation({
        variables: {
          cartId,
          cartItems: [cartItem],
        },
      }),
    [],
  )

  return [updateCartItems]
}

export default useUpdateCartItems
