import { getIsBraceletMyMove } from '../../utils/Product.utils'

export const MY_MOVE_COLOR_ATTRIBUTE_NAME = 'couleur_metal'
export const BRACELET_MY_MOVE_SIZE_ATTRIBUTE_NAME = 'taille_de_bracelet'
export const BRACELET_MY_MOVE_COLOR_ATTRIBUTE_NAME = 'leather_color'

export type ProductConfigurationFormData =
  | {
      typeProduct: 'SimpleProduct'
      carving?: {
        text: string
        font: string
      }
      productOption?: number
    }
  | {
      typeProduct: 'ConfigurableProduct'
      isBraceletMyMove: false
      carving?: {
        text: string
        font: string
      }
      productOption?: number
      configurableOption: number
    }
  | {
      typeProduct: 'ConfigurableProduct'
      isBraceletMyMove: true
      carving?: {
        text: string
        font: string
      }
      productOption: number
      configurableOptionSize: number
      configurableOptionColor: number
    }
  | {
      typeProduct: 'BundleProduct'
      myMoveColor?: number
      braceletMyMoveSize?: number
      braceletMyMoveColor?: number
    }

export const getProductConfigurationDefaultValues = (
  product: Product,
): Partial<ProductConfigurationFormData> => {
  // SimpleProduct
  if (product.__typename === 'SimpleProduct') {
    return {
      typeProduct: 'SimpleProduct',
    }
  }

  // BundleProduct
  if (product.__typename === 'BundleProduct') {
    return {
      typeProduct: 'BundleProduct',
    }
  }

  // ConfigurableProduct - Bracelet My Move
  if (getIsBraceletMyMove(product)) {
    return {
      typeProduct: 'ConfigurableProduct',
      isBraceletMyMove: true,
    }
  }

  // ConfigurableProduct
  return {
    typeProduct: 'ConfigurableProduct',
    isBraceletMyMove: false,
  }
}
