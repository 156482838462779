'use client'
import { useContext, useMemo } from 'react'
import { ConfigContext, useTranslation } from '../../context/ConfigContext'
import { findVariant, getIsInStock } from '../../utils/Product.utils'
import CustomSelect from '../Form/InputWrapper/CustomSelect'

type ProductConfigurationConfigurableOptionsProps = {
  product: Product
  value?: number

  onChange: (valueIndex: number) => void
  disabledOptionsWhenNotInStock?: boolean
}

export const ProductConfigurationSelectConfigurableOption = ({
  product,
  value,
  onChange,
  disabledOptionsWhenNotInStock = false,
}: ProductConfigurationConfigurableOptionsProps) => {
  const option = product.configurable_options?.[0]
  const t = useTranslation()

  const transformedValues = useMemo(() => {
    if (!option?.values) {
      return null
    }

    return option.values.map((v) => {
      const variant = findVariant(product, [
        { code: option.attribute_code ?? '', value: v?.value_index ?? 0 },
      ])
      const isInStock = !!(variant?.product && getIsInStock(variant.product))

      return {
        valueIndex: v?.value_index,
        label: v?.label,
        isInStock,
      }
    })

    // In case we need to remove the out of stock size
    // if (disabledOptionsWhenNotInStock) {
    //   return result.filter((item) => item.isInStock === true)
    // }

    // return result
  }, [product, option])

  if (!option || !transformedValues) {
    return null
  }

  const { isEcommerce } = useContext(ConfigContext)

  return (
    <>
      {isEcommerce() && (
        <CustomSelect
          label={option.label ?? ''}
          hookedLink={['tour_de_doigt', 'taille_de_bracelet'].includes(option.attribute_code || '')}
          typeHookedLink={product.type_extended?.id}
          onChange={onChange}
          selectedValue={value}
          list={
            transformedValues.map((value) => ({
              title: `${value?.label} ${
                !value?.isInStock ? ` - ${t('Not available', {}, false)}` : ''
              }`,
              value: value.valueIndex ?? 0,
              id: value?.valueIndex ?? 0,
              disabled: disabledOptionsWhenNotInStock && !value.isInStock,
            })) ?? []
          }
        />
      )}
    </>
  )
}

export default ProductConfigurationSelectConfigurableOption
