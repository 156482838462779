import { FunctionComponent } from 'react'
import { useTranslation } from '../../context/ConfigContext'
import { UseFormRegister } from 'react-hook-form'

export const civilities = [null, 'M.', 'Mrs', 'Other']

export const Civility: FunctionComponent<{ register: UseFormRegister<any>; name: string }> = ({
  register,
  name,
}) => (
  <select {...register(name)}>
    <CivilityOptions />
  </select>
)

export const CivilityOptions: FunctionComponent = () => {
  const t = useTranslation()

  return (
    <>
      <option value=''>
        {'' || t('product_alert_popup_civility_placeholder', {}, false)}
      </option>
      <option value='M.'>
        {t('M.') || t('product_alert_popup_civility_placeholder', {}, false)}
      </option>
      <option value='Mrs'>
        {t('Mrs') || t('product_alert_popup_civility_placeholder', {}, false)}
      </option>
      <option value='Other'>
        {t('Other') || t('product_alert_popup_civility_placeholder', {}, false)}
      </option>
    </>
  )
}
