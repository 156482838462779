import { useCallback, useContext } from 'react'
import { CartItem } from '../../../domain/cart'
import { CartContext } from '../../../presentation/context/CartContext'
import useUpdateCartItems from './useUpdateCartItems'

const useUpdateCarvingOfCartItem = () => {
  const [updateCartItems] = useUpdateCartItems()
  const { cart, setCart } = useContext(CartContext)

  const updateCarving = useCallback(
    async (cartItem: CartItem, carving?: Carving) => {
      try {
        const data = await updateCartItems(cart.id!, {
          cart_item_id: cartItem.id,
          engraving_options: {
            engraving_font: carving?.font ?? null,
            engraving_text: carving?.text ?? null,
            has_engraving: !!carving,
          },
          quantity: cartItem.quantity,
        })

        if (data.data?.updateCartItems?.cart) {
          // @ts-ignore
          setCart(data.data.updateCartItems.cart)
          return {
            success: true,
            error: null,
          }
        }

        throw new Error((data.errors && data.errors[0] && data.errors[0].message) || '')
      } catch (e: any) {
        return {
          success: false,
          error: e.message || '',
        }
      }
    },
    [cart.id, updateCartItems],
  )

  return updateCarving
}

export default useUpdateCarvingOfCartItem
