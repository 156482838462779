'use client'

import { FunctionComponent, useContext, useState } from 'react'
import { useTranslation } from '../../context/ConfigContext'
import { ModalContext, ModalContextType } from '../../context/ModalContext'
import { Button } from '../Button/Button'
import { Modal } from './Modal'
import Image from '../Image/Image'
import { findOneProductMediaGalleryByRole } from '../../utils/Product.utils'
import { ROLE_SMALL } from '../../utils/media.utils'
import { useIsMobile } from '../../utils/screen.utils'

interface ModalCarvingProps extends ModalInterface {
  onConfirm: (carving: Carving) => void
  onRemove: () => void
  product: Product
  initialValue?: {
    text: string
    font: string
  }
}
const ModalCarving: FunctionComponent<ModalCarvingProps> = ({
  uuid,
  onClose,
  onConfirm,
  onRemove,
  product,
  initialValue,
}) => {
  const [text, setText] = useState<string | null>(initialValue?.text ?? null)
  const [font, setFont] = useState<string>(initialValue ? initialValue.font : 'stick')
  const { removeModal } = useContext(ModalContext) as ModalContextType
  const t = useTranslation()
  const isMobile = useIsMobile()

  const handleAddCarving = () => {
    if (text) {
      onConfirm({
        text,
        font,
      })
    }
    removeModal(uuid)
  }

  const image = product.media_gallery
    ? findOneProductMediaGalleryByRole(product.media_gallery, ROLE_SMALL)
    : null

  return (
    <Modal uuid={uuid} dark={false} showLogo={false} center anchor='center' onClose={onClose}>
      <div className='modal-carving'>
        {!isMobile && <picture>{image && <Image src={image.small_image ?? ''} alt='' />}</picture>}
        <section>
          <h4 className='title-h4'>{t('Add engraving', {}, true)}</h4>
          <p className='infos'>{t('catalog-product-engraving-desc', {}, true)} </p>

          <div className='container-input container-previsualisation'>
            <label className='small'>{t('Preview:', {}, true)} </label>
            <input className={`preview ${font}`} disabled value={text ?? ''} />
          </div>

          <div className='container-input container-craving'>
            <label className='small' htmlFor='craving'>
              {t('Engraving text:', {}, true)}
            </label>
            <input
              className='input-text'
              name='craving'
              maxLength={22}
              onChange={(e) => setText(e.target.value)}
              defaultValue={text ?? ''}
            />
          </div>

          <div className='craving-button-group'>
            <Button
              classname={` ${font === 'stick' ? 'black' : 'border-grey'}`}
              label={t('Sticks Font', {}, false)}
              onClick={() => setFont('stick')}
            />
            <Button
              classname={`${font === 'english' ? 'black' : 'border-grey'}`}
              label={t('British Font', {}, false)}
              onClick={() => setFont('english')}
            />
          </div>

          <p className='small'>{t('Expect an additional delay of 5 days.', {}, true)}</p>

          <div className='save-button-group'>
            {text && text.length > 0 && (
              <Button
                classname='white'
                label={t('Remove', {}, false)}
                onClick={() => {
                  setText(null)
                  onRemove()
                }}
              />
            )}
            <Button
              classname='black'
              label={t('Add', {}, false)}
              onClick={handleAddCarving}
              disabled={!(text && text?.length > 0)}
            />
          </div>
        </section>
      </div>
    </Modal>
  )
}

export default ModalCarving
